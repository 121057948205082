import {httpProvider} from "@Newfiling/Services";

export const store = (data) => {
    return httpProvider.post('/eAdminPaymentTbs', data);
};

export const fetchItems = (data) => {
    return httpProvider.post('/eAdminPaymentlist', data);
};
export const deletePayment = (id) => {
    return httpProvider.delete(`/delPayment/${id}`);
};
