<template>
  <div class="page-login-desktop">
    <v-card flat>
      <v-card-title class="text-body-1 font-weight-bold d-flex align-center justify-space-between">
        ثبت پرداخت جدید
        <v-btn @click="$router.back()" text color="main" large>
          بازگشت
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="4">
            <v-text-field
                outlined
                label="شماره موبایل"
                hide-details
                v-model="payment.Customermobile"
            />
          </v-col>
          <v-col :cols="4">
            <v-select
                outlined
                label="نوع پرداخت"
                hide-details
                v-model="payment.Paymenttype"
                :items="paymentTypes"
                item-value="id"
                item-text="name"
            />
          </v-col>
          <v-col :cols="4">
            <v-text-field
                outlined
                label="قیمت"
                hide-details
                v-model="payment.price"
            />
          </v-col>
          <v-col :cols="12">
            <v-textarea
                outlined
                label="توضیحات"
                hide-details
                v-model="payment.Subscriptionname"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
            :loading="payment.loading"
            :disabled="payment.loading || !(payment.Customermobile && payment.price && payment.Paymenttype)"
            @click="submit"
            elevation="0"
            large color="main" class="white--text">
          ثبت تراکنش
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {store} from "@Newfiling/module-payment/src/api";
import Vue from "vue";
import {removeCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopLogin',

  data() {
    return {
      payment: {
        loading: false,
        Customermobile: "",
        Paymenttype: "",
        price: "",
        Subscriptionname: ""

      },
      paymentTypes: [
        {
          id: 1,
          name: 'زرین پال'
        },
        {
          id: 2,
          name: 'کارت به کارت'
        },
        {
          id: 3,
          name: 'نقدی'
        },
      ],

    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست تراکنش ها',
        disabled: false,
        href: '/panel/payments/list',
      },
      {
        text: 'افزودن تراکنش',
        disabled: true,
        href: '#',
      },
    ])
  },

  methods: {
    async submit() {
      this.payment.loading = true;
      try {
        await store({
          ...this.payment,
          price: removeCommas(this.payment.price)
        })
        this.$toast.success('تراکنش با موفقیت اضافه شد.');
        this.payment = {
          ...this.payment,
          Customermobile: "",
          Paymenttype: "",
          price: "",
          Subscriptionname: "",
        }
      } catch (e) {
        console.log(e)
      }
      this.payment.loading = false
    }
  },

  watch: {
    'payment.price'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.payment.price = result);
    }
  }

}
</script>
